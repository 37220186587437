var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { FadeIn, FadeOut } from "./ModalFade";
import "./PopupMessage.css";
export var PopupMessage = function (props) {
    var _a = useState(false), triggerFadeIn = _a[0], setTriggerFadeIn = _a[1];
    var reset = function () {
        window.location.reload();
    };
    var fadeBackIn = function () {
        setTriggerFadeIn(true);
        // animation duration delay
        setTimeout(function () {
            props.OnClose();
        }, 500);
    };
    return (_jsxs(_Fragment, { children: [!triggerFadeIn && (_jsxs("div", __assign({ id: "Popup_Container" }, { children: [_jsx("img", { src: "./Images/Misc/Popup_Background.svg", alt: "popup_background" }), _jsx("h1", __assign({ id: "Popup_Header" }, { children: props.Header })), _jsx("p", __assign({ id: "Popup_Body" }, { children: props.Body })), props.useRefreshButton && (_jsx("button", __assign({ id: "Popup_RefreshBtn", onClick: reset }, { children: "Refresh" }))), props.useCloseButton && (_jsx("button", __assign({ id: "Popup_CloseBtn", onClick: fadeBackIn }, { children: "Close Message" })))] }))), !triggerFadeIn && _jsx(FadeOut, { isFullFade: false }), triggerFadeIn && _jsx(FadeIn, { isFullFade: false })] }));
};
PopupMessage.defaultProps = {
    useCloseButton: true,
    useRefreshButton: false,
};
