var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import "./ParallaxBackground.css";
export var ParallaxBackground = function (props) {
    // this refreshes the view. Tried using state index but failed to update past index 1
    var _a = useState(0), imageUpdate = _a[0], setImageUpdate = _a[1];
    var imageIndex = useRef(0);
    var switchImageSetup = useRef(false);
    var imageArray = [
        "./Images/Loading/LoadingParallax_1.webp",
        "./Images/Loading/LoadingParallax_2.webp",
        "./Images/Loading/LoadingParallax_3.webp",
        "./Images/Loading/LoadingParallax_4.webp",
        "./Images/Loading/LoadingParallax_5.webp",
    ];
    setTimeout(function () {
        if (switchImageSetup.current)
            return;
        var parallaxImage = document.getElementById("parallaxImg");
        parallaxImage === null || parallaxImage === void 0 ? void 0 : parallaxImage.addEventListener("animationiteration", function () {
            switchImage();
        });
        switchImageSetup.current = true;
    }, 100);
    var switchImage = function () {
        var nextIndex = imageIndex.current + 1;
        imageIndex.current = nextIndex >= imageArray.length ? 0 : nextIndex;
        setImageUpdate(nextIndex);
    };
    return (_jsxs(_Fragment, { children: [_jsx("img", { id: "parallaxForeground", alt: "LoadingForeground", src: "./Images/Loading/LoadingForeground.png" }), _jsx("div", __assign({ id: "parallaxImgHolder" }, { children: _jsx("img", { id: "parallaxImg", alt: "ParallaxImg", src: imageArray[imageIndex.current] }) })), _jsx("div", { id: "parallaxFade" })] }));
};
