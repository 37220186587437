// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fadeOutModal {
    animation: 0.5s ease-out 0s modal-fade-out forwards;
}

#fadeInModal {
    animation: 0.5s ease-out 0s modal-fade-in forwards;
}

#fadeOutModal_Partial {
    animation: 0.5s ease-out 0s partial-modal-fade-out forwards;
}

#fadeInModal_Partial {
    animation: 0.5s ease-out 0s partial-modal-fade-in forwards;
}

.fadeOutModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: black;
    
    z-index: 100;
    opacity: 0;
}

.fadeInModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: black;
    
    z-index: 100;
    opacity: 1;
}


@keyframes modal-fade-out {

    from {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes modal-fade-in {

    from {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes partial-modal-fade-out {

    from {
        opacity: 0
    }

    100% {
        opacity: 0.90
    }
}

@keyframes partial-modal-fade-in {

    from {
        opacity: 0.90
    }

    100% {
        opacity: 0
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ModalFade.css"],"names":[],"mappings":"AAAA;IACI,mDAAmD;AACvD;;AAEA;IACI,kDAAkD;AACtD;;AAEA;IACI,2DAA2D;AAC/D;;AAEA;IACI,0DAA0D;AAC9D;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,uBAAuB;;IAEvB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,uBAAuB;;IAEvB,YAAY;IACZ,UAAU;AACd;;;AAGA;;IAEI;QACI;IACJ;;IAEA;QACI;IACJ;AACJ;;AAEA;;IAEI;QACI;IACJ;;IAEA;QACI;IACJ;AACJ;;AAEA;;IAEI;QACI;IACJ;;IAEA;QACI;IACJ;AACJ;;AAEA;;IAEI;QACI;IACJ;;IAEA;QACI;IACJ;AACJ","sourcesContent":["#fadeOutModal {\r\n    animation: 0.5s ease-out 0s modal-fade-out forwards;\r\n}\r\n\r\n#fadeInModal {\r\n    animation: 0.5s ease-out 0s modal-fade-in forwards;\r\n}\r\n\r\n#fadeOutModal_Partial {\r\n    animation: 0.5s ease-out 0s partial-modal-fade-out forwards;\r\n}\r\n\r\n#fadeInModal_Partial {\r\n    animation: 0.5s ease-out 0s partial-modal-fade-in forwards;\r\n}\r\n\r\n.fadeOutModal {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    top: 0%;\r\n    left: 0%;\r\n    background-color: black;\r\n    \r\n    z-index: 100;\r\n    opacity: 0;\r\n}\r\n\r\n.fadeInModal {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    top: 0%;\r\n    left: 0%;\r\n    background-color: black;\r\n    \r\n    z-index: 100;\r\n    opacity: 1;\r\n}\r\n\r\n\r\n@keyframes modal-fade-out {\r\n\r\n    from {\r\n        opacity: 0\r\n    }\r\n\r\n    100% {\r\n        opacity: 1\r\n    }\r\n}\r\n\r\n@keyframes modal-fade-in {\r\n\r\n    from {\r\n        opacity: 1\r\n    }\r\n\r\n    100% {\r\n        opacity: 0\r\n    }\r\n}\r\n\r\n@keyframes partial-modal-fade-out {\r\n\r\n    from {\r\n        opacity: 0\r\n    }\r\n\r\n    100% {\r\n        opacity: 0.90\r\n    }\r\n}\r\n\r\n@keyframes partial-modal-fade-in {\r\n\r\n    from {\r\n        opacity: 0.90\r\n    }\r\n\r\n    100% {\r\n        opacity: 0\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
