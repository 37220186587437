var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { StreamerStatus } from "@pureweb/platform-sdk";
import { useEffect, useRef, useState } from "react";
import { FadeIn, FadeOut } from "./ModalFade";
import { ParallaxBackground } from "./ParallaxBackground";
import "./Loading.css";
export var LoadingView = function (props) {
    var _a = useState(false), fadedOut = _a[0], setFadedOut = _a[1];
    var _b = useState("Loading"), loadingMessage = _b[0], setLoadingMessage = _b[1];
    var fadingOut = useRef(false);
    var timing = useRef(false);
    var content;
    var message;
    useEffect(function () {
        if (timing.current)
            return;
        timing.current = true;
        setTimeout(function () {
            timing.current = false;
            switch (loadingMessage) {
                case "Loading":
                    setLoadingMessage("Loading.");
                    break;
                case "Loading.":
                    setLoadingMessage("Loading..");
                    break;
                case "Loading..":
                    setLoadingMessage("Loading...");
                    break;
                case "Loading...":
                    setLoadingMessage("Loading");
                    break;
            }
        }, 650);
    }, [loadingMessage]);
    if (!props.LoadingDone && fadedOut && fadingOut) {
        setFadedOut(false);
        fadingOut.current = false;
    }
    if ((props.StreamerStatus === StreamerStatus.Connected ||
        props.StreamerStatus === StreamerStatus.Completed) &&
        props.LoadingDone) {
        if (!fadingOut.current) {
            // start fadeOut
            fadingOut.current = true;
        }
    }
    message = loadingMessage;
    content = (_jsxs("div", __assign({ id: "loadingContainer" }, { children: [_jsx(ParallaxBackground, {}), _jsxs("div", __assign({ id: "textHolder" }, { children: [_jsx("h2", __assign({ id: "mainLoadingText", className: "loadingText fadeInText" }, { children: message })), _jsx("h2", __assign({ id: "extraLoadingText", className: "loadingText" }, { children: "For a premium experience, load times vary from 1-2 minutes" }))] }))] })));
    return (_jsxs(_Fragment, { children: [_jsx(FadeIn, {}), props.LoadingDone && _jsx(FadeOut, {}), _jsx("div", __assign({ id: "loadingViewContent" }, { children: content }))] }));
};
