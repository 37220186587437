// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.optionsList {
    list-style: none outside none;
    margin-left: 0;
    padding-left: 0em;
    text-indent: -0.5em;
    letter-spacing: 0.05em;
}

.optionsListItem {
    color: white;
    line-height: 120%;

    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/PackageOptions.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,iBAAiB;;IAEjB,yBAAyB;AAC7B","sourcesContent":[".optionsList {\r\n    list-style: none outside none;\r\n    margin-left: 0;\r\n    padding-left: 0em;\r\n    text-indent: -0.5em;\r\n    letter-spacing: 0.05em;\r\n}\r\n\r\n.optionsListItem {\r\n    color: white;\r\n    line-height: 120%;\r\n\r\n    text-transform: uppercase;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
