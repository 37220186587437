var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { DebugLog } from "../HelperFunctions";
import "./LogIn.css";
import { TopBarView } from "./TopBar";
import { PasswordReset } from "./PasswordReset";
import logger from "../Log";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather";
import { eye } from "react-icons-kit/feather";
import axios from "axios";
export var LogInView = function (props) {
    var submitDisabled = useRef(false);
    var _a = useState(false), resettingPW = _a[0], setResettingPW = _a[1];
    var _b = useState(""), email = _b[0], setEmail = _b[1];
    var _c = useState(""), password = _c[0], setPassword = _c[1];
    var _d = useState(false), inputError = _d[0], setInputError = _d[1];
    var errorMessage_Email = useRef("");
    var errorMessage_Password = useRef("");
    var emailMessageClasses = useRef("");
    var passwordMessageClasses = useRef("");
    var fullBarMessageClasses = useRef("");
    var _e = useState(false), isLoadingLogin = _e[0], setLoadingLogin = _e[1];
    var _f = useState("Loading"), loadingMessage = _f[0], setLoadingMessage = _f[1];
    var timing = useRef(false);
    var _g = useState("password"), type = _g[0], setType = _g[1];
    var _h = useState("logInInputs_StartAnim"), logInAnimClass = _h[0], setLogInAnimClass = _h[1];
    var _j = useState("forgotPasswordButton_StartAnim"), passwordResetBtnAnimClass = _j[0], setPWResetAnimClass = _j[1];
    var _k = useState("submitButton_StartAnim"), submitBtn_AnimClass = _k[0], setSubmitBtn_AnimClass = _k[1];
    var _l = useState("submitButtonHighlight_StartAnim"), submitHighlight_AnimClass = _l[0], setSubmitHighlight_AnimClass = _l[1];
    var _m = useState("outlineWhite"), emailOutlineClass = _m[0], setEmailOutlineClass = _m[1];
    var _o = useState("outlineWhite"), passwordOutlineClass = _o[0], setPasswordOutlineClass = _o[1];
    var emailInput = document.getElementById("emailInput");
    var passwordInput = document.getElementById("passwordInput");
    var loginErrorPopup_ID = "LogIn_ErrorContainer";
    var whiteOutlineClass = "outlineWhite";
    var redOutlineClass = "outlineRed";
    // Check autofill and fill in username & password if used
    // ** Necessary since onChange not called by autofill **
    setTimeout(function () {
        if (emailInput === null || emailInput === void 0 ? void 0 : emailInput.matches(":autofill")) {
            setEmail(emailInput.value ? emailInput.value : "");
        }
        if (passwordInput === null || passwordInput === void 0 ? void 0 : passwordInput.matches(":autofill")) {
            setPassword(passwordInput.value ? passwordInput.value : "");
        }
    }, 500);
    var BlurLogic = function (e) {
        var inputValue = e.target.value;
        if (inputValue === "") {
            e.target.value = e.target.defaultValue;
        }
    };
    var FocusLogic = function (e) {
        var inputValue = e.target.value;
        if (inputValue === e.target.defaultValue) {
            e.target.value = "";
        }
    };
    var SetEmail = function (e) {
        setEmail(e.target.value);
        setEmailOutlineClass(whiteOutlineClass);
        resetError();
    };
    var SetPassword = function (e) {
        setPassword(e.target.value);
        setPasswordOutlineClass(whiteOutlineClass);
        resetError();
    };
    var Submit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var logInResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(emailInput === null ||
                        !emailInput.validity.valid ||
                        emailInput.value === "")) return [3 /*break*/, 1];
                    popupError("Invalid Email Input", true);
                    setEmailOutlineClass(redOutlineClass);
                    return [3 /*break*/, 4];
                case 1:
                    if (!(passwordInput.value.length === 0)) return [3 /*break*/, 2];
                    popupError("Invalid Password Input", false);
                    setPasswordOutlineClass(redOutlineClass);
                    return [3 /*break*/, 4];
                case 2:
                    if (!!submitDisabled.current) return [3 /*break*/, 4];
                    submitDisabled.current = true;
                    setLoadingLogin(true);
                    return [4 /*yield*/, props.LogIn(email, password)];
                case 3:
                    logInResponse = _a.sent();
                    setLoadingLogin(false);
                    if (logInResponse === "success") {
                        startEndAnimations();
                    }
                    else if (logInResponse === "timeout") {
                        popupError("Server Timed Out - Try Again Later", true, true);
                        submitDisabled.current = false;
                    }
                    else {
                        popupError("Invalid Credentials", true);
                        submitDisabled.current = false;
                    }
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var popupError = function (errorMSG, isEmail, fullBar) {
        if (fullBar === void 0) { fullBar = false; }
        emailMessageClasses.current =
            isEmail && !fullBar ? "logInBar_AnimIn_Email" : "";
        passwordMessageClasses.current =
            !isEmail && !fullBar ? "logInBar_AnimIn_Password" : "";
        fullBarMessageClasses.current = fullBar ? "logInBar_AnimIn_FullBar" : "";
        errorMessage_Email.current = isEmail ? errorMSG : "";
        errorMessage_Password.current = !isEmail ? errorMSG : "";
        setInputError(true);
        setTimeout(function () {
            var _a;
            (_a = document.getElementById(loginErrorPopup_ID)) === null || _a === void 0 ? void 0 : _a.focus();
        }, 50);
    };
    var resetError = function () {
        setTimeout(function () {
            setInputError(false);
        }, 300);
    };
    useEffect(function () {
        if (timing.current)
            return;
        timing.current = true;
        setTimeout(function () {
            timing.current = false;
            switch (loadingMessage) {
                case "Loading":
                    setLoadingMessage("Loading.");
                    break;
                case "Loading.":
                    setLoadingMessage("Loading..");
                    break;
                case "Loading..":
                    setLoadingMessage("Loading...");
                    break;
                case "Loading...":
                    setLoadingMessage("Loading");
                    break;
            }
        }, 650);
    }, [loadingMessage]);
    var startEndAnimations = function () {
        // replace animation classes
        setLogInAnimClass("logInInputs_EndAnim");
        setPWResetAnimClass("forgotPasswordButton_EndAnim");
        setSubmitBtn_AnimClass("submitButton_EndAnim");
        setSubmitHighlight_AnimClass("submitButtonHighlight_EndAnim");
    };
    var SummonPWResetComponent = function () {
        setResettingPW(true);
    };
    var SubmitPWReset = function (emailForReset) { return __awaiter(void 0, void 0, void 0, function () {
        var options, url, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(emailForReset !== "")) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    options = {
                        method: "POST",
                        headers: {
                            "Access-Control-Allow-Headers": "Content-Type",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "POST",
                            "Content-Type": "application/json",
                        },
                        data: emailForReset,
                    };
                    url = process.env.REACT_APP_API + "/Login/ForgotPassword";
                    return [4 /*yield*/, axios(url, options).then(function (res) {
                            var status = res.status;
                            if (status === 200) {
                                DebugLog("Succesfully Reset Password");
                            }
                            else {
                                DebugLog("Password was not reset");
                            }
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logger.error(err_1);
                    return [3 /*break*/, 4];
                case 4:
                    setTimeout(function () {
                        setResettingPW(false);
                    }, 200);
                    return [2 /*return*/];
            }
        });
    }); };
    var togglePasswordVisibility = function () {
        setType(type === "password" ? "text" : "password");
    };
    var stuff = (_jsxs(_Fragment, { children: [resettingPW && (_jsx(PasswordReset, { EmailAddress: email, Submit: SubmitPWReset })), isLoadingLogin && _jsx("div", { id: "loadingLogin" }), _jsx(TopBarView, { BarSelect: 0 }), _jsx("div", __assign({ id: "logInContainer" }, { children: _jsxs("div", __assign({ id: "logInDiv" }, { children: [inputError && (_jsxs("a", __assign({ href: "", id: "LogIn_ErrorContainer", className: "ErrorContainer", style: { color: "#747474" } }, { children: [_jsx("img", { id: "logInBar_message_email", className: emailMessageClasses.current, src: "./Images/LogIn/LogInBar_Message.png", alt: "ErrorMessage_BGImg" }), _jsx("img", { id: "logInBar_message_password", className: passwordMessageClasses.current, src: "./Images/LogIn/LogInBar_Message.png", alt: "ErrorMessage_BGImg" }), _jsx("img", { id: "logInBar_message_fullBar", className: fullBarMessageClasses.current, src: "./Images/LogIn/LogInBar_Message.png", alt: "ErrorMessage_BGImg" }), _jsx("span", __assign({ id: "span_message_email" }, { children: errorMessage_Email.current })), _jsx("span", __assign({ id: "span_message_password" }, { children: errorMessage_Password.current }))] }))), _jsx("div", __assign({ className: "mask_logInInputs" }, { children: _jsxs("div", __assign({ id: "logInInputs", className: logInAnimClass }, { children: [_jsx("img", { id: "logInBar", src: "./Images/LogIn/LogInBar.webp", alt: "BottomBar" }), _jsx("div", { id: "email_outline", className: emailOutlineClass }), _jsx("div", { id: "password_outline", className: passwordOutlineClass }), _jsx("input", { id: "emailInput", className: "textInputs", type: "email", placeholder: "EMAIL ADDRESS", onChange: function (e) { return SetEmail(e); }, onFocus: function (e) { return FocusLogic(e); }, onBlur: function (e) { return BlurLogic(e); } }), _jsx("input", { id: "passwordInput", className: "textInputs", type: type, placeholder: "PASSWORD", onChange: function (e) { return SetPassword(e); }, onFocus: function (e) {
                                            FocusLogic(e);
                                        }, onBlur: function (e) { return BlurLogic(e); } }), _jsx("button", __assign({ id: "password-visibility-button", "aria-label": "password visibility toggle", onClick: togglePasswordVisibility }, { children: _jsx(Icon, { id: "pw-icon", icon: type === "password" ? eyeOff : eye }) }))] })) })), _jsx("button", __assign({ id: "submitButton", "aria-label": "Submit", className: submitBtn_AnimClass, onClick: Submit }, { children: _jsx("img", { src: "./Images/LogIn/Submit_Image.png", alt: "", style: {
                                    position: "absolute",
                                    width: "101%",
                                    height: "100%",
                                    left: "-1%",
                                    bottom: "0%",
                                    backgroundColor: "black",
                                } }) })), _jsx("img", { id: "submitButtonHighlight", className: submitHighlight_AnimClass, src: "./Images/LogIn/Submit_Image_Highlight.png", alt: "Submit" }), isLoadingLogin && (_jsx("img", { id: "submitButtonNoText", alt: "Submit", src: "./Images/LogIn/Submit_Image_NoText.png" })), isLoadingLogin && _jsx("span", __assign({ id: "loadingText" }, { children: loadingMessage })), _jsx("button", __assign({ id: "forgotPasswordButton", className: passwordResetBtnAnimClass, onClick: SummonPWResetComponent }, { children: _jsx("span", __assign({ id: "fpw_span" }, { children: "Forgot Password" })) }))] })) }))] }));
    return stuff;
};
