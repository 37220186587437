// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#testDiv {
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    position: absolute;
}

.testColor1 {
    background-color: white;
    opacity: 0;
}

.testColor2 {
    background-color: aqua;
    opacity: 0.2;
}

.testColorError {
    background-color: red;
    opacity: 0.2;
}

`, "",{"version":3,"sources":["webpack://./src/components/BackgroundVideo.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,MAAM;IACN,OAAO;;IAEP,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":["#testDiv {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    top: 0;\r\n    left: 0;\r\n\r\n    position: absolute;\r\n}\r\n\r\n.testColor1 {\r\n    background-color: white;\r\n    opacity: 0;\r\n}\r\n\r\n.testColor2 {\r\n    background-color: aqua;\r\n    opacity: 0.2;\r\n}\r\n\r\n.testColorError {\r\n    background-color: red;\r\n    opacity: 0.2;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
