var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactPlayer from "react-player/file";
import "./BackgroundVideo.css";
import { useState } from "react";
export var BackgroundVideo = function (props) {
    var content;
    var _a = useState("testColor1"), testVisualClass = _a[0], setTestVisualClass = _a[1];
    var onPlayerReady = function () {
        setTestVisualClass("testColor2");
    };
    var onError = function (e) {
        setTestVisualClass("testColorError");
        console.log("onError", e);
    };
    content = (_jsx("div", __assign({ id: "ReactPlayerVideo", style: {
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0",
            top: "2px",
            justifyContent: "center",
            alignContent: "center",
        } }, { children: _jsx(ReactPlayer, { width: "100%", height: "auto", url: "./Videos/B-Roll.mp4", playing: true, loop: true, muted: true, pip: false, controls: false, playsinline: true, onReady: onPlayerReady, onError: onError, config: {
                attributes: {
                    disablePictureInPicture: false,
                },
            } }) })));
    return content;
};
