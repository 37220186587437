var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { TopBarView } from "./TopBar";
import "../Reset.css";
import "./Start.css";
export var StartView = function (props) {
    var _a = useState(false), submitDisabled = _a[0], setSubmitDisabled = _a[1];
    var TryStart = function () {
        if (submitDisabled)
            return;
        setSubmitDisabled(true);
        props.Start();
    };
    return (_jsxs(_Fragment, { children: [_jsx(TopBarView, { BarSelect: 0 }), _jsx("div", __assign({ id: "startContainer" }, { children: _jsx("div", __assign({ id: "startRatio" }, { children: _jsxs("div", __assign({ id: "startDiv" }, { children: [_jsx("button", { id: "startButton", "aria-label": "Start", tabIndex: 0, onClick: function () { return TryStart(); } }), _jsx("img", { id: "startButtonBackground", alt: "startButtonImage" }), _jsx("img", { id: "startButtonHighlight", alt: "startButtonHoverImage" }), _jsx("img", { id: "startButtonImage", alt: "startButtonImage" })] })) })) }))] }));
};
