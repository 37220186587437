// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#parallaxForeground {
    position: relative;

    z-index: 5;

    width: 100%;
    height: 100%;
}

#parallaxImgHolder {
    position: absolute;
  
    aspect-ratio: 16/9;

    width: 100%;
    max-width: 177.778vh;
    max-height: 56.25vw;

    overflow: hidden;

    z-index: 4;
}

#parallaxImg {
    position: absolute;
    width: 120%;

    z-index: 3;

    animation: parallax-anim 10s ease-in-out 0s infinite;
}

#parallaxFade {
    z-index: 4;

    position: absolute;

    width: 100%;
    height: 100%;

    background-color: black; 
    opacity: 0;

    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
    animation: fade-out-to-in 10s ease-in-out 0s infinite normal none running;
}

@keyframes parallax-anim {
    from {
        left: 0%;
    }

    to {
        left: -20%;
    }
}

@keyframes fade-out-to-in {
    from {
        opacity: 1;
    }

    10% {
        opacity: 1;
    }

    20% { 
        opacity: 0
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ParallaxBackground.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,UAAU;;IAEV,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;;IAElB,kBAAkB;;IAElB,WAAW;IACX,oBAAoB;IACpB,mBAAmB;;IAEnB,gBAAgB;;IAEhB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;;IAEX,UAAU;;IAEV,oDAAoD;AACxD;;AAEA;IACI,UAAU;;IAEV,kBAAkB;;IAElB,WAAW;IACX,YAAY;;IAEZ,uBAAuB;IACvB,UAAU;;IAEV,wFAAwF;IACxF,yEAAyE;AAC7E;;AAEA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI;IACJ;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":["#parallaxForeground {\r\n    position: relative;\r\n\r\n    z-index: 5;\r\n\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n#parallaxImgHolder {\r\n    position: absolute;\r\n  \r\n    aspect-ratio: 16/9;\r\n\r\n    width: 100%;\r\n    max-width: 177.778vh;\r\n    max-height: 56.25vw;\r\n\r\n    overflow: hidden;\r\n\r\n    z-index: 4;\r\n}\r\n\r\n#parallaxImg {\r\n    position: absolute;\r\n    width: 120%;\r\n\r\n    z-index: 3;\r\n\r\n    animation: parallax-anim 10s ease-in-out 0s infinite;\r\n}\r\n\r\n#parallaxFade {\r\n    z-index: 4;\r\n\r\n    position: absolute;\r\n\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    background-color: black; \r\n    opacity: 0;\r\n\r\n    /* animation: name duration timing-function delay iteration-count direction fill-mode; */\r\n    animation: fade-out-to-in 10s ease-in-out 0s infinite normal none running;\r\n}\r\n\r\n@keyframes parallax-anim {\r\n    from {\r\n        left: 0%;\r\n    }\r\n\r\n    to {\r\n        left: -20%;\r\n    }\r\n}\r\n\r\n@keyframes fade-out-to-in {\r\n    from {\r\n        opacity: 1;\r\n    }\r\n\r\n    10% {\r\n        opacity: 1;\r\n    }\r\n\r\n    20% { \r\n        opacity: 0\r\n    }\r\n\r\n    80% {\r\n        opacity: 0;\r\n    }\r\n\r\n    90% {\r\n        opacity: 0;\r\n    }\r\n\r\n    to {\r\n        opacity: 1;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
