// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* - MASTER.RESET <<<--------*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, hr, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, active, hover, selected {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    text-decoration: none;
}

ol,ul {
    list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/Reset.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;;;;;;;;;;;;;IAaI,SAAS;IACT,UAAU;IACV,SAAS;IACT,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["/* - MASTER.RESET <<<--------*/\r\n\r\nhtml, body, div, span, applet, object, iframe,\r\nh1, h2, h3, h4, h5, h6, hr, p, blockquote, pre,\r\na, abbr, acronym, address, big, cite, code,\r\ndel, dfn, em, img, ins, kbd, q, s, samp,\r\nsmall, strike, strong, sub, sup, tt, var,\r\nb, u, i, center,\r\ndl, dt, dd, ol, ul, li,\r\nfieldset, form, label, legend,\r\ntable, caption, tbody, tfoot, thead, tr, th, td,\r\narticle, aside, canvas, details, embed, \r\nfigure, figcaption, footer, header, hgroup, \r\nmenu, nav, output, ruby, section, summary,\r\ntime, mark, audio, video, active, hover, selected {\r\n    margin: 0;\r\n    padding: 0;\r\n    border: 0;\r\n    outline: none;\r\n    text-decoration: none;\r\n}\r\n\r\nol,ul {\r\n    list-style: none;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
