var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import "./PackageOptions.css";
export var PackageOptions = function (props) {
    var options = props.optionStrings;
    var num = 0;
    var optionsList = options.map(function (option) {
        num++;
        var uniqueKey = "uID" + num;
        return (_jsx("li", __assign({ className: "optionsListItem" }, { children: option }), uniqueKey));
    });
    return (_jsx(_Fragment, { children: _jsx("ul", __assign({ className: "optionsList" }, { children: optionsList })) }));
};
