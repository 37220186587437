var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "./TopBar.css";
export var TopBarView = function (props) {
    var displayString = props.DisplayText === undefined ? "" : props.DisplayText;
    var topBarID = props.BarSelect === 0 ? "defaultTopBarHolder" : "selectTopBarHolder";
    var barAnimClass = props.SetToHide
        ? "outroTopBar"
        : props.BarSelect === 1
            ? "introTopBar"
            : "";
    return (_jsx("div", __assign({ id: "topBarRoot" }, { children: _jsx("div", __assign({ id: "topBarRatio" }, { children: _jsxs("div", __assign({ id: topBarID, className: barAnimClass }, { children: [props.BarSelect === 0 && (_jsx("img", { id: "topBarImg", src: "./Images/Misc/TopBar_Intro.png", alt: "Top Bar" })), props.BarSelect === 1 && (_jsx("img", { id: "selectionTopBarImg", src: "./Images/Misc/TopBar_Selection.png", alt: "Top Bar" })), displayString !== "" && (_jsxs("h1", __assign({ id: "topBarText" }, { children: [_jsx("span", __assign({ style: { color: "white" } }, { children: "Select" })), " ", _jsx("span", __assign({ style: { color: "red" } }, { children: displayString }))] })))] })) })) })));
};
