import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import "./ModalFade.css";
export var FadeIn = function (props) {
    var fadeId = props.isFullFade ? "fadeInModal" : "fadeInModal_Partial";
    return (_jsx(_Fragment, { children: _jsx("div", { id: fadeId, className: "fadeInModal" }) }));
};
FadeIn.defaultProps = {
    isFullFade: true,
};
export var FadeOut = function (props) {
    var fadeId = props.isFullFade ? "fadeOutModal" : "fadeOutModal_Partial";
    return (_jsx(_Fragment, { children: _jsx("div", { id: fadeId, className: "fadeOutModal" }) }));
};
FadeOut.defaultProps = {
    isFullFade: true,
};
