var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { FadeIn, FadeOut } from "./ModalFade";
import { DebugLog } from "../HelperFunctions";
import "./PasswordReset.css";
import FocusTrap from "focus-trap-react";
export var PasswordReset = function (props) {
    var _a = useState(false), triggerFadeIn = _a[0], setTriggerFadeIn = _a[1];
    var _b = useState(false), inputError = _b[0], setInputError = _b[1];
    var _c = useState(false), propHasSetEmail = _c[0], setPropHasSetEmail = _c[1];
    var doneInitialEmailFocus = useRef(false);
    var email = "";
    // KEEP: emailTemp is null w/o delay
    if (!doneInitialEmailFocus.current)
        setTimeout(function () {
            email = props.EmailAddress;
            var emailTemp = document.getElementById("PWR_EmailInput");
            if (emailTemp) {
                if (!propHasSetEmail) {
                    emailTemp.value = email;
                    setPropHasSetEmail(true);
                }
                DebugLog("setting timeout focus");
                emailTemp.focus();
                doneInitialEmailFocus.current = true;
            }
        }, 10);
    var reset = function () {
        var emailInput = document.getElementById("PWR_EmailInput");
        if (emailInput && emailInput.value !== "" && emailInput.validity.valid) {
            fadeBackIn();
            props.Submit(emailInput.value);
        }
        else {
            DebugLog("Invalid email address");
            setInputError(true);
            setTimeout(function () {
                var _a;
                (_a = document.getElementById("PWReset_ErrorContainer")) === null || _a === void 0 ? void 0 : _a.focus();
            }, 50);
        }
    };
    var resetError = function () {
        setInputError(false);
    };
    var cancel = function () {
        fadeBackIn();
        props.Submit("");
    };
    var fadeBackIn = function () {
        setTriggerFadeIn(true);
    };
    var SetEmail = function (e) {
        email = e.target.value;
    };
    return (_jsxs(_Fragment, { children: [!triggerFadeIn && (_jsx(FocusTrap, { children: _jsxs("div", __assign({ id: "PW_Reset_Container" }, { children: [_jsx("img", { src: "./Images/Misc/Popup_Background.svg", alt: "popup_background" }), _jsxs("span", __assign({ id: "PWR_HeaderSpan" }, { children: ["Please enter your email address. ", _jsx("br", {}), "We will send you an email to begin the password reset process."] })), _jsx("span", __assign({ id: "PWR_TextInputLabel" }, { children: "Email Address" })), _jsx("input", { id: "PWR_EmailInput", type: "email", "aria-label": "email to reset", onChange: function (e) { return SetEmail(e); } }), inputError && (_jsx("a", __assign({ href: "", id: "PWReset_ErrorContainer", className: "ErrorContainer", style: { color: "#747474" }, tabIndex: 0, onBlur: resetError }, { children: _jsx("div", __assign({ id: "PWReset_ErrorPopup" }, { children: _jsx("p", __assign({ role: "alert" }, { children: "Invalid Email Input" })) })) }))), _jsx("button", __assign({ id: "PWR_ResetBtn", onClick: reset, "aria-label": "Reset Password" }, { children: "Reset" })), _jsx("button", __assign({ id: "PWR_CancelBtn", onClick: cancel, "aria-label": "Cancel" }, { children: "Cancel" }))] })) })), !triggerFadeIn && _jsx(FadeOut, { isFullFade: false }), triggerFadeIn && _jsx(FadeIn, { isFullFade: false })] }));
};
